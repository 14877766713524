exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pursuits-africa-js": () => import("./../../../src/pages/pursuits/africa.js" /* webpackChunkName: "component---src-pages-pursuits-africa-js" */),
  "component---src-pages-pursuits-climbing-js": () => import("./../../../src/pages/pursuits/climbing.js" /* webpackChunkName: "component---src-pages-pursuits-climbing-js" */),
  "component---src-pages-pursuits-development-js": () => import("./../../../src/pages/pursuits/development.js" /* webpackChunkName: "component---src-pages-pursuits-development-js" */),
  "component---src-pages-pursuits-freelancing-js": () => import("./../../../src/pages/pursuits/freelancing.js" /* webpackChunkName: "component---src-pages-pursuits-freelancing-js" */),
  "component---src-pages-pursuits-index-js": () => import("./../../../src/pages/pursuits/index.js" /* webpackChunkName: "component---src-pages-pursuits-index-js" */),
  "component---src-pages-pursuits-physics-js": () => import("./../../../src/pages/pursuits/physics.js" /* webpackChunkName: "component---src-pages-pursuits-physics-js" */),
  "component---src-pages-pursuits-pilot-js": () => import("./../../../src/pages/pursuits/pilot.js" /* webpackChunkName: "component---src-pages-pursuits-pilot-js" */),
  "component---src-pages-pursuits-research-js": () => import("./../../../src/pages/pursuits/research.js" /* webpackChunkName: "component---src-pages-pursuits-research-js" */)
}

